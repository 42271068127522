import CustomImage from "@components/CustomImage/CustomImage";
import { useTranslation } from "react-i18next";

export default function Company() {
  const paymentMethods = [
    "paypal",
    "mastercard",
    "visa",
    "maestro",
    "sofort",
    "googlepay",
    "applepay",
    "mangopay",
  ];

  const { t } = useTranslation();
  return (
    <div className="flex flex-col md:px-0  md:self-start  text-left">
      <p className="font-bold text-lg mb-[8px]">{t("main.Sichere_Zahlung")}</p>
      <div className="w-[75%] xl:w-[70%] grid grid-cols-4 gap-1">
        {paymentMethods?.map((type) => (
          <div
            key={type}
            className={`${
              type === "mangopay"
                ? "col-span-2 bg-[#fcb069]"
                : "col-span-1 bg-white"
            } rounded-xl flex items-center justify-center`}
          >
            <CustomImage
              src={`static/global/footer/payment/${type}.png`}
              alt={type}
              urlWidth={type === "mangopay" ? 180 : 80}
              className={`rounded-xl ${
                type === "mangopay"
                  ? "object-contain"
                  : "object-cover object-center"
              }`}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
