export const API_ENDPOINTS = {
  FRONTPAGE: "/site/index",
  OFFER: "/offer/page",
  SEARCH: "/offer/search",
  SEARCH_FILTER: "/offer/filters",
  CATEGORIES: "categories",
  REVIEWS: "offer/page/reviews",
  RECOMMENDATION: "offer/page/recommendation",
  offer_suggestion: "search/v2/related-offers",
  NEWSLATTER: "newsletter",
  CONTACTUS: "contact-us",
  LANDINGPAGES: "landing-page",
  landingOffers: (querystring) => `/site/index/landing/${querystring}`,
  voucherlist: "/voucher/items",
  viewVoucher: "/voucher/item",
  sendVoucherData: "voucher/checkout",
  sendVoucherPay: "/voucher/payment",
  voucherCart: "/voucher/cart",
  listWishlist: "/user/profile/wishlist",
  createShared: "/user/profile/wishlist/share-create",
  DeleteWishlist: "/user/profile/wishlist/delete",
  listWishitemView: "/user/profile/wishlist/show",
  listWishitemShared: "/wishlist/share-view",
  addwishlistItem: "/user/profile/wishlist/item/create",
  removeItemWishlist: "/user/profile/wishlist/item/delete",
  createWishlist: "/user/profile/wishlist/create",
  userWishlistUpdate: "/user/profile/wishlist/update",
  CartPriceCheck: "/booking/check/date",
  cartInfo: "/booking/card/details",
  cartData: "/booking/card/details",
  installment: "/booking/card/installment",
  remaining: "/booking/card/installment/details",
  getRooms: "/booking/room/show",
  addRoom: (querystring) => `/booking/room/add?${querystring}`,
  removeRoom: (querystring) => `/booking/room/delete?${querystring}`,
  contactRequestProvider: "/communication/create",
  getExtras: (querystring) => `/booking/extra/show?${querystring}`,
  addExtras: (querystring) => `/booking/extra/add?${querystring}`,
  removeExtras: (querystring) => `/booking/extra/delete?${querystring}`,
  getInfo: (querystring) => `/booking/customerdata/show?${querystring}`,
  submitBooking: (querystring) => `/booking/customerdata/create?${querystring}`,
  paymentCode: (querystring) => `/booking/paymentCode?${querystring}`,
  paymentCheck: (querystring) => `/booking/paymentCheck?${querystring}`,
  installmentPayCheck: (querystring) => `/booking/card/installment/backstripe`,
  paymentLink: (querystring) => `/booking/paymentLink?${querystring}`,
  paymentSofort: (querystring) => `/booking/paymentSofort?${querystring}`,
  paymentKlarna: (querystring) => `/booking/paymentKlarna?${querystring}`,
  paymentPaypal: (querystring) => `/booking/paymentPaypal?${querystring}`,
  couponCheck: (querystring) => `/booking/couponCheck?${querystring}`,
  paymentSubmit: (querystring) => `/booking/paymentSubmit?${querystring}`,
  LOGIN: "/auth/login/phone",
  LoginSocial: "/auth/login-social/callbacklogin",
  MoBileOtpLogin: "/auth/confirm/code",
  AuthResendOtp: "/auth/confirm/code",
  AuthLoginEmail: "/auth/login/email",
  AuthRegister: "/auth/register/email",
  AuthReset: "/auth/forgetpassword",
  AuthResetnewPass: "/auth/newpass",
  CountryList: "/user/profile/nationalities",
  CategoryAndLevel: "/user/profile/category",
  UpdateProfileInfo: "/user/profile/update",
  SENDNOTIF: "/user/profile/newslatter",
  LOGOUT: "/user/profile/auth/logout",
  DEACTIVE: "/user/profile/auth/dective",
  DELETE: "/user/profile/delete",
  CHANGEPASS: "/user/profile/auth/changepassword",
  UserInfo: "/user/profile/info",
  bookingView: "/user/profile/booking",
  BookingList: "/user/profile/booking",
  arrivalSend: "/user/profile/booking/customdata",
  reportBook: "/user/profile/booking/conflict",
  rescheduleBook: "/user/profile/booking/rescheduled",
  updateProfilePhoto: "/user/profile/photo/update",
  removeProfilePhoto: "/user/profile/photo/remove",
  AuthLoginAdmin: "/auth/login/user",
  SEARCHTRIPFINDER: "/offer/trip-finder",
  SUGGESTIONLIST: "/offer/suggestion",
  blogFrontPage: "/blog/front/index",
  blogView: "/blog/front/post",
  typeList: "/user/profile/booking/conflict/type",
  cancelingView: "/user/profile/booking/cancelation/info",
  cancelBooking: "/user/profile/booking/cancel",
  blogCategories: "/blog/front/categories",
  blogSendComment: "/blog/front/comment-add",
  blogListComment: "/blog/front/comments",
  blogCategoryPost: "/blog/front/posts",
  offerReview: "/offer/reviews/submit",
  bookingCustomData: "/user/profile/booking/guestdata",
  ProviderView: (querystring) => `/provider/info/${querystring}`,
  ProviderReview: (querystring) => `/provider/review/${querystring}`,
  providerViewContactUs: "/communication/chat-provider",
};
