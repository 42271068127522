import AuthController from "@controller/auth/AuthController";
import Footer from "@layout/footer/footer";
import Header from "@layout/header/header";
import { setUser } from "@libs/request/localstorageService";
import classNames from "classnames";
import Cookies from "js-cookie";
import { inject, observer } from "mobx-react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import GoogleAutoLogin from "./header/GoogleAutoLogin";
import { useTranslation } from "react-i18next";
import moment from "moment";
const controller = new AuthController();

const BaseLayout = inject("CoreStore")(
  observer(({ children, page, CoreStore, domain }) => {
    const router = useRouter();
    const { t, i18n } = useTranslation("common");

    useEffect(() => {
      if (
        CoreStore?.isAuth == 1 &&
        CoreStore?.token &&
        !localStorage?.getItem("user") &&
        Cookies?.get("user") &&
        CoreStore?.user?.email === "" &&
        CoreStore?.user?.name === "" &&
        CoreStore?.user?.last_name === "" &&
        CoreStore?.user?.phone === ""
      ) {
        const userData = JSON.parse(Cookies.get("user"));
        setUser(userData);
        return router.reload();
      }

      const isRememberedStorage = window.localStorage.getItem("rememberLogin");
      const isRememberedCookie = Cookies.get("rememberLogin");

      if (CoreStore?.isAuth == 0) {
        if (isRememberedStorage) {
          return controller.emailLogin(JSON.parse(isRememberedStorage));
        } else if (isRememberedCookie) {
          return controller.emailLogin(JSON.parse(isRememberedCookie));
        }
      }

      if (
        CoreStore?.isAuth == 1 &&
        !localStorage?.getItem("user") &&
        !Cookies?.get("user")
      ) {
        if (isRememberedStorage) {
          return controller.emailLogin(JSON.parse(isRememberedStorage));
        } else if (isRememberedCookie) {
          return controller.emailLogin(JSON.parse(isRememberedCookie));
        }
      }
    }, [CoreStore]);

    const changeLanguage = (lang) => {
      try {
        i18n.changeLanguage(lang);
        moment.locale(lang);
        CoreStore.setLang(lang);
      } catch (e) {
        console.log("language change failed", e);
      }
    };

    useEffect(() => {
      // Detect domain and set locale
      if (
        router.isReady &&
        domain &&
        typeof domain === "string" &&
        CoreStore?.isAuth == 0
      ) {
        if (domain?.includes(".de")) {
          if (i18n.language === "en") {
            // change lang to de
            // changeLanguage("de");
            // console.log("Domain identified: .de");
            // console.log("Language change to: DE");
          }
        } else if (domain?.includes(".com")) {
          if (i18n.language === "de") {
            // change lang to en
            changeLanguage("en");
            console.log("Language change to: EN");
          }
          console.log("Domain identified: .com");
        }
      }

      // for testing
      if (router.asPath === "/user/wishlist") {
        console.log("Domain: ", domain);

        if (domain && typeof domain === "string") {
          console.log("Is on .com", domain?.includes(".com"));
          console.log("Is on .de", domain?.includes(".de"));
        }
      }
    }, [router.isReady, CoreStore, domain]);

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 640px)" });
    const hideHeader =
      isTabletOrMobile && router.pathname === "/offer" && !!router.query?.code;

    return (
      <div>
        {CoreStore?.isAuth == 0 && <GoogleAutoLogin router={router} />}
        <Header
          className={classNames({ hidden: hideHeader })}
          page={page}
          router={router}
        />
        <div className="min-h-[93vh]">{children}</div>
        <Footer />
      </div>
    );
  })
);
export const getLayout = (page) => <BaseLayout>{page}</BaseLayout>;
export default BaseLayout;
