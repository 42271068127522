import CustomImage from "@components/CustomImage/CustomImage";
import useWindowSize from "src/hooks/useWindowSize";
import request from "@libs/request/Api";
import { inject, observer } from "mobx-react";
import { useCurrency } from "src/hooks/CurrencyContext";
import { useTranslation } from "react-i18next";
import { IoCloseOutline } from "react-icons/io5";
import { TfiHeart } from "react-icons/tfi";
import { IoClose } from "react-icons/io5";
import { GrLanguage } from "react-icons/gr";
import { Avatar, Drawer, Dropdown, Modal, Spin } from "antd";
import Cookies from "js-cookie";
import moment from "moment";
import Link from "next/link";
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { IoIosSearch, IoIosArrowDown } from "react-icons/io";
import { FaRegHeart } from "react-icons/fa6";
import { CgProfile } from "react-icons/cg";
import { FiLogIn } from "react-icons/fi";

const HeaderSearchContainer = dynamic(
  () =>
    import("@components/NewSearchBar/HeaderSearchBar/HeaderSearchContainer"),
  {
    ssr: false,
  }
);
const Login = dynamic(() => import("./login"), {
  ssr: false,
});

// import HeaderMainSearch from "@components/search/headerMainSearch";

const Header = inject("CoreStore")(
  observer((props) => {
    const { CoreStore, className, router, page } = props;

    const { t, i18n } = useTranslation("common");

    const [lang, setLang] = useState();
    const [status, setStatus] = useState(false);
    const [logoutModal, setLogoutModal] = useState(false);
    const [openLangModal, setOpenLangModal] = useState(false);
    const [isLangLoading, setIsLangLoading] = useState(false);
    const [disableCurrency, setDisableCurrency] = useState(false);
    const [openSearchBar, setOpenSearchBar] = useState(false);

    const { currency, setCurrency } = useCurrency();
    const { width } = useWindowSize();
    const isSmallLaptop = width <= 1300 && width >= 999;
    const isSmall = width < 850;

    const handleclose = (open) => {
      props.CoreStore.loginModalAction(open);
    };

    const _openSearch = () => {
      setOpenSearchBar((prev) => !prev);

      // props.CoreStore.setOpenSearch(!props.CoreStore.openSearch);
      // setStatus(false);
    };

    useEffect(() => {
      if (page === "detailed-booking") {
        setDisableCurrency(router.query?.status !== "reserved");
      } else {
        setDisableCurrency(false);
      }
    }, [router.query, page]);

    const country = [
      {
        title: t("lang_german"),
        icon: "/icon/search/germany.svg",
        lang: "de",
      },
      {
        title: t("lang_english"),
        icon: "/icon/search/england.svg",
        lang: "en",
      },
    ];

    const currencies = [
      {
        title: "Euro",
        alias: "EUR",
        symbol: "€",
      },
      {
        title: t("main.language_modal_gbp"),
        alias: "GBP",
        symbol: "£",
      },
      {
        title: t("main.language_modal_dollar"),
        alias: "USD",
        symbol: "$",
      },
      {
        title: t("main.language_modal_CHF"),
        alias: "CHF",
        symbol: "CHF",
      },
    ];

    useEffect(() => {
      setLang(() => localStorage.getItem("lang") || "de");
    });

    const handleSelectLang = (lang) => {
      if (props.CoreStore.isAuth == 0) {
        i18n.changeLanguage(lang);
        moment.locale(lang);
        try {
          props.CoreStore.setLang(lang);
        } catch (e) {}
      } else {
        request
          .post("/user/profile/langauge", { lang: lang })
          .then((result) => {
            i18n.changeLanguage(lang);
            moment.locale(lang);
            try {
              props.CoreStore.setLang(lang);
            } catch (e) {}

            router.reload();
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      setTimeout(() => {
        setIsLangLoading(false);
      }, 5000);
    };

    const handleCurrencyChange = async (newCurrency) => {
      if (disableCurrency) return;

      await setCurrency(newCurrency);
      Cookies.set("currency", newCurrency);

      if (typeof window !== undefined) {
        localStorage.setItem("currency", newCurrency);
      }

      await router.reload();
    };

    const loggedMenuItems = isSmall
      ? [
          {
            key: "1",
            label: (
              <Link href="/communication">
                <a>{t("headMenu.messages")}</a>
              </Link>
            ),
            icon: <img src="/static/icons/message.svg" alt="Messages" />,
          },
          {
            key: "2",
            label: (
              <Link href="/user/booking">
                <a>{t("headMenu.journeys")}</a>
              </Link>
            ),
            icon: <img src="/static/icons/journey.svg" alt="Journeys" />,
          },
          {
            key: "3",
            label: (
              <Link href="/user/profile">
                <a>{t("headMenu.account")}</a>
              </Link>
            ),
            icon: <img src="/static/icons/profile.svg" alt="Profile" />,
          },
          {
            key: "4",
            label: (
              <Link href="/user/wishlist">
                <a>{t("headMenu.favorites")}</a>
              </Link>
            ),
            icon: <TfiHeart className="text-base font-normal" />,
          },
          {
            key: "5",
            label: t("headMenu.logout"),
            icon: <img src="/static/icons/logout.svg" alt="Logout" />,
            onClick: () => setLogoutModal(true),
          },
        ]
      : [
          {
            key: "1",
            label: (
              <Link href="/communication">
                <a className="ml-2" href="/communication">
                  {t("headMenu.messages")}
                </a>
              </Link>
            ),
            icon: (
              <CustomImage
                src="static/icons/message.svg"
                alt="message"
                loading="eager"
                width={18}
              />
            ),
          },
          {
            key: "2",
            label: (
              <Link href="/user/booking">
                <a className="ml-2" href="/user/booking">
                  {t("headMenu.journeys")}
                </a>
              </Link>
            ),
            icon: (
              <CustomImage
                src="static/icons/journey.svg"
                alt="journey"
                loading="eager"
                width={18}
              />
            ),
          },
          {
            key: "3",
            label: (
              <Link href="/user/profile">
                <a className="ml-2" href="/user/profile">
                  {t("headMenu.account")}
                </a>
              </Link>
            ),
            icon: (
              <CustomImage
                src="static/icons/profile.svg"
                alt="profile"
                loading="eager"
                width={16}
              />
            ),
          },
          {
            key: "4",
            label: <p className="ml-2">{t("headMenu.logout")}</p>,
            icon: (
              <CustomImage
                src="static/icons/logout.svg"
                alt="logout"
                loading="eager"
                width={17}
              />
            ),
            onClick: () => setLogoutModal(true),
          },
        ];

    const _renderUserMenu = () => {
      return (
        <div className={"flex flex-row"}>
          <Dropdown
            menu={{
              items: loggedMenuItems,
            }}
            placement="bottomRight"
            className={
              "cursor-pointer hover:border-b hover:border-orange  px-4 py-2"
            }
          >
            <div className={"flex items-center space-x-3"}>
              <Avatar
                src={
                  CoreStore.user.photo ||
                  process.env.NEXT_PUBLIC_IMAGE_CDN +
                    "static/icons/avatar.svg?format=auto&width=32"
                }
                alt={CoreStore.user.name}
              >
                {CoreStore.user.name}
              </Avatar>
              <div>
                {CoreStore.user.name == null ? "User" : CoreStore.user.name}
              </div>
              <IoIosArrowDown className="text-xl text-black" />
            </div>
          </Dropdown>
        </div>
      );
    };

    return (
      <header className={className}>
        <nav
          className={`bg-white py-2.5 shadow-sm ${
            page === "offer" && isSmallLaptop ? "!px-0" : "px-6"
          }`}
        >
          <div
            className={`container flex flex-wrap justify-between items-center ${
              (page === "offer" || page === "search") && isSmallLaptop
                ? "!px-5"
                : "px-0"
            }`}
          >
            <div
              className={`img-logo ${
                page === "berlin"
                  ? "flex items-center gap-x-5 sm:gap-x-0 lg:gap-x-5"
                  : ""
              }`}
            >
              <a href={"/"}>
                <CustomImage
                  src="static/logo/moverii_logo.svg"
                  alt="moverii.de"
                  loading="eager"
                  width={100}
                  height={24}
                />
              </a>

              {page === "berlin" && (
                <div className="items-center gap-x-1 hidden lg:flex">
                  <CustomImage
                    src="static/logo/berliner-leserreisen-logo.webp"
                    alt="Berliner Leserreisen"
                    loading="eager"
                    width={38}
                  />
                  <p className="text-sm font-medium">
                    Berliner <br /> Leserreisen
                  </p>
                </div>
              )}
            </div>

            {page === "berlin" && (
              <div className="items-center gap-x-1 flex sm:hidden">
                <CustomImage
                  src="static/logo/berliner-leserreisen-logo.webp"
                  alt="Berliner Leserreisen"
                  loading="eager"
                  width={38}
                />
                <p className="text-sm font-medium">
                  Berliner <br /> Leserreisen
                </p>
              </div>
            )}

            <button
              data-collapse-toggle="mobile-menu"
              type="button"
              onClick={() => setStatus(true)}
              className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="mobile-menu"
              aria-expanded="false"
              aria-label="mobile-menu"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <svg
                className="hidden w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>

            <div className="hidden w-full md:flex md:w-auto">
              <div className="flex items-center mr-[28px]">
                <a
                  href="https://provider.moverii.de"
                  target="_blank"
                  className="text-[#2b2d42] text-sm font-medium"
                >
                  {t("main.for_partner")}
                </a>
              </div>

              <ul className="flex p-0 m-0 flex-row space-x-7 md:mt-0 md:text-sm md:font-medium">
                {/* Language & currency */}
                <li
                  className="flex items-center gap-x-1.5 cursor-pointer"
                  onClick={() => setOpenLangModal(true)}
                >
                  <p>
                    {i18n.language === "en"
                      ? t("main.language_modal_english")
                      : t("main.language_modal_german")}
                  </p>
                  <span className="-mt-[2px] text-sm">|</span>
                  <p className="uppercase">{currency || "EUR"}</p>
                </li>
                {!isSmall && (
                  <li className="flex self-center space-x-1 cursor-pointer">
                    <Link href={"/user/wishlist"}>
                      <div className="flex gap-x-1 items-center">
                        <TfiHeart className="text-base font-normal" />
                        {t("headMenu.favorites")}
                      </div>
                    </Link>
                  </li>
                )}
                <li
                  className="flex self-center gap-x-1 cursor-pointer"
                  onClick={_openSearch}
                >
                  <div>
                    <IoIosSearch className="text-[19px] text-black" />
                  </div>
                  <p className={"flex"}>{t("main.Suche")}</p>
                </li>
                {CoreStore.isAuth != 1 && (
                  <li
                    className="flex self-center space-x-1 cursor-pointer"
                    onClick={() => {
                      props.CoreStore.changeViewMod("login");
                      handleclose(true);
                    }}
                  >
                    <div>
                      <CgProfile className="text-[18px] text-black !-mb-[2px]" />
                    </div>
                    <p className={"flex"}>{t("main.login_action")}</p>
                  </li>
                )}
                {CoreStore.isAuth != 1 ? (
                  <button
                    className="main-btn"
                    onClick={() => {
                      props.CoreStore.changeViewMod("register");
                      handleclose(true);
                    }}
                    aria-label={t("main.Registrieren")}
                  >
                    {t("main.Registrieren")}
                  </button>
                ) : (
                  <>{_renderUserMenu()}</>
                )}
              </ul>
            </div>
          </div>
        </nav>

        {status && (
          <Drawer
            size="xs"
            open={status}
            onClose={() => setStatus(false)}
            placement={"right"}
            className="sideNavMenu"
            closable={false}
            title={
              <div className="flex items-center justify-between">
                <CustomImage
                  src="static/logo/moverii_logo.svg"
                  alt="moverii.de"
                  loading="lazy"
                  width={100}
                  height={24}
                  className="!-ml-4"
                />
                <IoCloseOutline
                  className="text-2xl"
                  onClick={() => setStatus(false)}
                />
              </div>
            }
          >
            {CoreStore.isAuth != 1 ? (
              <div className="flex flex-col gap-5 w-full h-full text-black font-heading">
                <span
                  className="flex items-center gap-2"
                  onClick={() => {
                    props.CoreStore.changeViewMod("register");
                    handleclose(true);
                    setStatus(false);
                  }}
                >
                  <span className="w-[30px] flex justify-start items-center">
                    <CgProfile className="text-[23px] text-black" />
                  </span>
                  <p className="text-base font-medium">
                    {t("main.mobile_register")}
                  </p>
                </span>
                <span
                  className="flex items-center gap-2 border-b pb-6"
                  onClick={() => {
                    props.CoreStore.changeViewMod("login");
                    handleclose(true);
                    setStatus(false);
                  }}
                >
                  <span className="w-[30px] flex justify-start items-center">
                    <FiLogIn className="text-[23px] text-black" />
                  </span>
                  <p className="text-base font-medium">
                    {t("main.mobile_login")}
                  </p>
                </span>
                <span
                  className="flex items-center gap-2 pt-3"
                  onClick={() => {
                    props.CoreStore.changeViewMod("login");
                    handleclose(true);
                    setStatus(false);
                  }}
                >
                  <span className="w-[30px] flex justify-start items-center">
                    <FaRegHeart className="text-[23px] text-black" />
                  </span>
                  <p className="text-base font-medium">
                    {t("main.mobile_whishlist")}
                  </p>
                </span>
                <span
                  className="flex items-center gap-2 border-b pb-6"
                  onClick={_openSearch}
                >
                  <span className="w-[30px] flex justify-start items-center">
                    <IoIosSearch className="text-[26px] text-black" />
                  </span>
                  <p className="text-base font-medium">
                    {t("main.mobile_search")}
                  </p>
                </span>
                <div
                  className="flex items-center gap-5"
                  onClick={() => {
                    setOpenLangModal(true);
                    setStatus(false);
                  }}
                >
                  <GrLanguage className={`text-2xl`} />
                  <div className="flex items-center gap-x-2 text-base font-medium">
                    <p>
                      {i18n.language === "en"
                        ? t("main.language_modal_english")
                        : t("main.language_modal_german")}
                    </p>
                    <span className="-mt-[2px] text-sm">|</span>
                    <p className="uppercase">{currency || "EUR"}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-5 w-full h-full text-black font-heading">
                <a
                  href={`${process.env.NEXT_PUBLIC_SITE_ADDRESS}en/communication`}
                  className="flex items-center gap-2"
                >
                  <span className="w-[30px] flex justify-start items-center">
                    <CustomImage
                      src="static/icons/message.svg"
                      alt="message"
                      loading="eager"
                      width={23}
                    />
                  </span>
                  <p className="text-base font-medium">
                    {t("main.mobile_logged_messages")}
                  </p>
                </a>
                <a
                  href={`${process.env.NEXT_PUBLIC_SITE_ADDRESS}en/user/booking`}
                  className="flex items-center gap-2 border-b pb-6"
                >
                  <span className="w-[30px] flex justify-start items-center">
                    <CustomImage
                      src="static/icons/journey.svg"
                      alt="journey"
                      loading="eager"
                      width={23}
                    />
                  </span>
                  <p className="text-base font-medium">
                    {t("main.mobile_logged_journeys")}
                  </p>
                </a>
                <a
                  href={`${process.env.NEXT_PUBLIC_SITE_ADDRESS}en/wishlist`}
                  className="flex items-center gap-2 pt-3"
                >
                  <span className="w-[30px] flex justify-start items-center">
                    <FaRegHeart className="text-[23px] text-black" />
                  </span>
                  <p className="text-base font-medium">
                    {t("main.mobile_whishlist")}
                  </p>
                </a>
                <a
                  href={`${process.env.NEXT_PUBLIC_SITE_ADDRESS}en/user/profile`}
                  className="flex items-center gap-2 border-b pb-6"
                >
                  <span className="w-[30px] flex justify-start items-center">
                    <CgProfile className="text-[23px] text-black" />
                  </span>
                  <p className="text-base font-medium">
                    {t("main.mobile_logged_account")}
                  </p>
                </a>
                <span className="flex items-center gap-2" onClick={_openSearch}>
                  <span className="w-[30px] flex justify-start items-center">
                    <IoIosSearch className="text-[26px] text-black" />
                  </span>
                  <p className="text-base font-medium">
                    {t("main.mobile_search")}
                  </p>
                </span>
                <span
                  className="flex items-center gap-2 border-b pb-6"
                  onClick={() => {
                    setLogoutModal(true);
                    setStatus(false);
                  }}
                >
                  <span className="w-[30px] flex justify-start items-center">
                    <CustomImage
                      src="static/icons/logout.svg"
                      alt="logout"
                      loading="eager"
                      width={24}
                    />
                  </span>
                  <p className="text-base font-medium">
                    {t("main.mobile_logged_logout")}
                  </p>
                </span>
                <div
                  className="flex items-center gap-5"
                  onClick={() => {
                    setOpenLangModal(true);
                    setStatus(false);
                  }}
                >
                  <GrLanguage className={`text-2xl`} />
                  <div className="flex items-center gap-x-2 text-base font-medium">
                    <p>
                      {i18n.language === "en"
                        ? t("main.language_modal_english")
                        : t("main.language_modal_german")}
                    </p>
                    <span className="-mt-[2px]">|</span>
                    <p className="uppercase">{currency || "EUR"}</p>
                  </div>
                </div>
              </div>
            )}
          </Drawer>
        )}

        {logoutModal && (
          <Modal
            open={logoutModal}
            onCancel={() => setLogoutModal(false)}
            footer={null}
            centered
            className="!w-[480px]"
          >
            <div className="flex gap-3">
              <CustomImage
                src="static/icons/logout.svg"
                alt="logout"
                loading="eager"
                width={24}
              />
              <p className="text-base font-medium">
                {t("main.logout_modal_text")}
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center mt-10 w-full">
              <button
                className="py-2 bg-orange rounded-[1.25rem] text-base text-white font-medium"
                onClick={() => setLogoutModal(false)}
                aria-label={t("main.logout_modal_cancel")}
              >
                {t("main.logout_modal_cancel")}
              </button>
              <button
                className="py-2 bg-white rounded-[1.25rem] text-base text-black font-medium border border-[#ff9f1c]"
                onClick={() => {
                  CoreStore.logout();
                  setLogoutModal(false);
                  Cookies.remove("rememberLogin");
                  window.localStorage.removeItem("rememberLogin");
                  window.localStorage.removeItem("favoriteOffers");
                }}
                aria-label={t("main.logout_modal_logout")}
              >
                {t("main.logout_modal_logout")}
              </button>
            </div>
          </Modal>
        )}

        {props.CoreStore.loginModal && (
          <Login
            open={props.CoreStore.loginModal}
            viewMod={props.CoreStore.viewMode}
            handleclose={handleclose}
            router={router}
          />
        )}

        {/* OLD SEARCH BAR */}
        {/* {props.CoreStore.openSearch && (
          <HeaderMainSearch open={props.CoreStore.openSearch} />
        )} */}

        {/* NEW SEARCH BAR */}
        {openSearchBar && (
          <HeaderSearchContainer
            open={openSearchBar}
            setOpen={setOpenSearchBar}
          />
        )}

        {/* Language & currency modal */}
        {openLangModal && (
          <Modal
            open={openLangModal}
            closable={false}
            centered
            footer={false}
            className="!w-full md:!w-[90%] lg:!w-[800px]"
          >
            <div className="w-full flex flex-col gap-6 md:pt-0 md:pb-2">
              <div className="w-full flex flex-col-reverse md:flex-row items-end md:justify-between md:items-center md:gap-6 pl-2">
                <p className="text-[28px] font-semibold leading-normal">
                  {t("main.language_modal_main_title")}
                </p>
                <div
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => setOpenLangModal(false)}
                >
                  <IoClose className="text-2xl" />
                </div>
              </div>

              {/* Language */}
              <div className="px-2 w-full">
                <p className="text-lg font-semibold">
                  {t("main.language_modal_language")}
                </p>
                <div className="w-full flex flex-col md:flex-row md:items-center mt-3 gap-3">
                  {country.map((lng) => (
                    <Spin spinning={isLangLoading} key={lng.title + lng.lang}>
                      <div
                        className={`w-full md:w-[165px] h-[60px] rounded-xl border flex items-center px-3 cursor-pointer shadow ${
                          i18n.language === lng.lang
                            ? "border-orange border-2 bg-[#E1F9FC]"
                            : "border-black bg-white"
                        }`}
                        onClick={() => {
                          setIsLangLoading(true);
                          handleSelectLang(lng.lang);
                        }}
                      >
                        <p className="font-bold text-base">{lng.title}</p>
                      </div>
                    </Spin>
                  ))}
                </div>
              </div>
              {/* Currency */}
              <div className="px-2 w-full pb-10 md:pb-0">
                <p className="text-lg font-semibold">
                  {t("main.language_modal_currency")}
                </p>
                <div className="w-full flex flex-col md:flex-row flex-wrap md:items-center mt-3 gap-3">
                  {currencies?.map((cur) => (
                    <div
                      key={cur.title + cur.alias + cur.symbol}
                      className={`min-w-full md:min-w-[165px] py-3 rounded-xl border flex flex-col items-start justify-center gap-0 px-3 cursor-pointer shadow ${
                        cur.alias === currency
                          ? "border-orange border-2 bg-[#E1F9FC]"
                          : "border-black bg-white"
                      } ${
                        disableCurrency
                          ? "!bg-gray-20 opacity-25 !cursor-not-allowed"
                          : ""
                      }`}
                      onClick={() => handleCurrencyChange(cur?.alias)}
                    >
                      <p className="font-bold text-base md:whitespace-nowrap">
                        {cur.title}
                      </p>
                      <p className="text-sm font-normal text-[#959499]">
                        {cur.alias === "CHF"
                          ? cur.alias
                          : `${cur.alias} - ${cur.symbol}`}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Modal>
        )}
      </header>
    );
  })
);
export default Header;
