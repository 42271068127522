import { Image } from "antd";

const CustomImage = ({
  src,
  width = "100%",
  height = "100%",
  format = "auto",
  preview = false,
  loading = "lazy",
  urlWidth,
  ...rest
}) => {
  const baseUrl = process.env.NEXT_PUBLIC_IMAGE_CDN;
  if (!baseUrl) {
    throw new Error("NEXT_PUBLIC_IMAGE_CDN is not defined in .env file");
  }

  const imageUrl = `${baseUrl}${src}?format=${format}&width=${
    urlWidth || width
  }`;

  return (
    <Image
      src={imageUrl}
      width={width}
      height={height}
      preview={preview}
      loading={loading}
      {...rest}
    />
  );
};

export default CustomImage;
