import CustomImage from "@components/CustomImage/CustomImage";

export default function SocialBox() {
  const icons = [
    {
      image: "instagram",
      link: "https://www.instagram.com/moverii/",
      alt: "moverii on instagram",
    },
    {
      image: "facebook",
      link: "https://www.facebook.com/moverii/",
      alt: "moverii on facebook",
    },
    {
      image: "youtube",
      link: "https://www.youtube.com/channel/UCkyVijJkqiIutKQacT3yP7w",
      alt: "moverii on youtube",
    },
  ];
  return (
    <div className="flex flex-col">
      <div className="flex flex-row space-x-4 mt-0">
        {icons.map((v, i) => {
          return (
            <div className="flex self-center" key={v.image + i}>
              <a href={v.link} target={"_blank"}>
                <CustomImage
                  src={`static/global/footer/${v.image}.svg`}
                  alt={v.alt}
                  width={30}
                  urlWidth={40}
                  height={30}
                  className="mr-1 ml-0 cursor-pointer"
                />
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}
