export default function Links({ items }) {
  return (
    <div className="block">
      <div className="font-bold text-lg pb-3">{items.title}</div>
      {items.data.map((v, i) => {
        return (
          <div className="flex align-center mb-2 item-links" key={v.txt + i}>
            <a
              href={process.env.NEXT_PUBLIC_SITE_ADDRESS + v.link}
              className="hover:text-orange txt-links focus:text-orange"
            >
              {v.txt}
            </a>
          </div>
        );
      })}
    </div>
  );
}
