import CustomImage from "@components/CustomImage/CustomImage";
import { useTranslation } from "react-i18next";

export default function Provide() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full items-start  self-start md:self-center text-center md:text-left">
      <p className="font-bold text-lg">{t("main.for_host")}</p>
      <div className="mt-2 mb-[28px]">
        <a
          href="https://provider.moverii.de/"
          className="!text-white hover:!text-orange"
          target="_blank"
        >
          {t("main.work_with_us")}
        </a>
      </div>

      <p className="font-bold text-lg">{t("main.vertrauen")}</p>
      <div className="flex w-full md:align-center justify-start self-start md:self-center mt-[8px] md:gap-2">
        <div>
          <CustomImage
            src="static/global/footer/trust-1.svg"
            alt="moverii trust badge 1"
            width={75}
            height={75}
          />
        </div>
        <div className="pt-1">
          <CustomImage
            src="static/global/footer/trust-2.png"
            alt="moverii trust badge 2"
            width={100}
            height={70}
          />
        </div>
      </div>
    </div>
  );
}
