import { useTranslation } from "react-i18next";

export default function Info() {
  const { t } = useTranslation();
  const s = "common:main.";

  return (
    <div className="box-info mt-1 md:px-0">
      <p className="md:text-left leading-7">{t(s + "footer_info_1")}</p>
    </div>
  );
}
